"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.computedCartInPreviewer = exports.useComputedCartInPreviewerFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _useCart = require("@meepshop/hooks/lib/gqls/useCart");

var _price = require("./price");

var _products = require("./products");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query computedCartInPreviewer($input: computedCartInput!) {\n    computedCart(input: $input) {\n      ... on ComputedCart {\n        totalPrice\n        computedLineItems {\n          id\n          ...productsInPreviewerLineItemFragment\n        }\n        ...priceInPreviewerFragment\n      }\n\n      ... on UnhandledError {\n        message\n      }\n    }\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useComputedCartInPreviewerFragment on User {\n    id\n    ...useCartFragment\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var useComputedCartInPreviewerFragment = (0, _client.gql)(_templateObject(), _useCart.useCartFragment);
exports.useComputedCartInPreviewerFragment = useComputedCartInPreviewerFragment;
var computedCartInPreviewer = (0, _client.gql)(_templateObject2(), _price.priceInPreviewerFragment, _products.productsInPreviewerLineItemFragment);
exports.computedCartInPreviewer = computedCartInPreviewer;