"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.priceInPreviewerFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _computedCart = require("@meepshop/apollo/lib/gqls/computedCart");

var _locale = require("@meepshop/utils/lib/gqls/locale");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment priceInPreviewerFragment on ComputedCart {\n    totalPrice\n    computedLineItems {\n      id\n      quantity\n      unitPrice\n      ...computedCartLineItemFragment\n    }\n    # FIXME: TK-1911 \u8CFC\u7269\u8ECA\u76F8\u95DC\u6298\u6263\u90FD\u5DF2\u7531\u5F8C\u7AEF\u8A08\u7B97\uFF0C\u61C9\u8A72\u4E0D\u6703\u5728\u9700\u8981\u524D\u7AEF\u81EA\u5B9A\u7FA9 schema \u7684\u90E8\u5206\uFF0C\u5F85\u89C0\u5BDF\u5F8C\u53EF\u79FB\u9664\n    productsDiscount @client {\n      activityId: id\n      discountPrice\n      title {\n        ...localeFragment\n      }\n    }\n    orderDiscount {\n      totalDiscount\n      discountAllocations {\n        id\n        discountPrice\n        title {\n          ...localeFragment\n        }\n      }\n    }\n    activityInfo {\n      id\n      title {\n        ...localeFragment\n      }\n      discountPrice\n      plugin\n    }\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var priceInPreviewerFragment = (0, _client.gql)(_templateObject(), _locale.localeFragment, _computedCart.computedCartLineItemFragment);
exports.priceInPreviewerFragment = priceInPreviewerFragment;