"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = require("react");

var _client = require("@apollo/client");

var _useCart2 = _interopRequireDefault(require("@meepshop/hooks/lib/useCart"));

var _filter = _interopRequireDefault(require("@meepshop/utils/lib/filter"));

var _useCart3 = require("@meepshop/hooks/lib/gqls/useCart");

var _useComputedCart = require("../gqls/useComputedCart");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _default = function _default(viewer) {
  var _useCart = (0, _useCart2["default"])((0, _filter["default"])(_useCart3.useCartFragment, viewer)),
      loading = _useCart.loading,
      cartItems = _useCart.cartItems;

  var cartItemsInput = (0, _react.useMemo)(function () {
    return cartItems.map(function (_ref) {
      var _ = _ref.__typename,
          cartItem = (0, _objectWithoutProperties2["default"])(_ref, ["__typename"]);
      return cartItem;
    });
  }, [cartItems]);

  var _useQuery = (0, _client.useQuery)(_useComputedCart.computedCartInPreviewer, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        cartItems: cartItemsInput
      }
    },
    skip: loading
  }),
      data = _useQuery.data;

  return (0, _react.useMemo)(function () {
    var _data$computedCart, _data$computedCart$co;

    if (!data || data.computedCart.__typename !== 'ComputedCart') return null;
    return _objectSpread(_objectSpread({}, data.computedCart), {}, {
      computedLineItems: ((_data$computedCart = data.computedCart) === null || _data$computedCart === void 0 ? void 0 : (_data$computedCart$co = _data$computedCart.computedLineItems) === null || _data$computedCart$co === void 0 ? void 0 : _data$computedCart$co.filter(Boolean)) || []
    });
  }, [data]);
};

exports["default"] = _default;