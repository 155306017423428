"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.array.reduce.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = require("react");

// import
// definition
var _default = function _default(activityInfo) {
  return (0, _react.useMemo)(function () {
    return (activityInfo || []).reduce(function (result, activity) {
      if (!activity) return result;
      var id = activity.id,
          plugin = activity.plugin,
          discountPrice = activity.discountPrice;
      if (plugin === 'freeShipping') return result;
      var existActivity = result.find(function (_ref) {
        var activityId = _ref.id;
        return activityId === id;
      });

      if (existActivity) {
        var newDiscountPrice = (existActivity.discountPrice || 0) + (discountPrice || 0);
        existActivity.discountPrice = newDiscountPrice;
        return result;
      }

      return [].concat((0, _toConsumableArray2["default"])(result), [activity]);
    }, []);
  }, [activityInfo]);
};

exports["default"] = _default;