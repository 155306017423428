"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.computedCartLineItemFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment computedCartLineItemFragment on LineItem {\n    id\n    discountAllocations {\n      activityId: id\n      discountPrice\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// difinition
var computedCartLineItemFragment = (0, _client.gql)(_templateObject());
exports.computedCartLineItemFragment = computedCartLineItemFragment;