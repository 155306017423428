"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.reduce.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var getKeys = function getKeys(pages) {
  return (pages || []).reduce(function (result, _ref) {
    var pageId = _ref.id,
        subPages = _ref.pages;
    return [].concat((0, _toConsumableArray2["default"])(result), (0, _toConsumableArray2["default"])(getKeys(subPages)), [pageId]);
  }, []);
};

var _default = getKeys;
exports["default"] = _default;