"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _propTypes = require("../constants/propTypes");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * Use to remove some props which are from contextTpye.
 * @param {Object} prevProps - props from component
 * @param {Array} extendRemove - the name of prop which is needed to be removed
 */
var _default = function _default(prevProps) {
  var extendRemove = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  var newProps = _objectSpread({}, prevProps);

  [].concat((0, _toConsumableArray2["default"])(Object.keys(_propTypes.CONTEXT_TYPES)), (0, _toConsumableArray2["default"])(extendRemove)).forEach(function (typeName) {
    if (newProps[typeName] !== undefined) delete newProps[typeName];
  });
  return newProps;
};

exports["default"] = _default;