"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.thumbnailFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment thumbnailFragment on Image {\n    id\n    imageExists\n    scaledSrc {\n      w60\n      w120\n      w240\n      w480\n      w720\n      w960\n      w1200\n      w1440\n      w1680\n      w1920\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var thumbnailFragment = (0, _client.gql)(_templateObject());
exports.thumbnailFragment = thumbnailFragment;