"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useProductsColumnsInPreviewerLineItemFragment = exports.useProductsColumnsInPreviewerUserFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _locale = require("@meepshop/utils/lib/gqls/locale");

var _useCart = require("@meepshop/hooks/lib/gqls/useCart");

var _gqls = require("@meepshop/thumbnail/lib/gqls");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useProductsColumnsInPreviewerLineItemFragment on LineItem {\n    id\n    productId\n    quantity\n    variantId\n    type\n    unitPrice\n    status\n    product {\n      displayToggle\n    }\n    discountAllocations {\n      activityId: id\n      title {\n        ...localeFragment\n      }\n    }\n    specs {\n      id\n      title {\n        ...localeFragment\n      }\n    }\n    title {\n      ...localeFragment\n    }\n    coverImage {\n      id\n      ...thumbnailFragment\n    }\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useProductsColumnsInPreviewerUserFragment on User {\n    id\n    ...useCartFragment\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var useProductsColumnsInPreviewerUserFragment = (0, _client.gql)(_templateObject(), _useCart.useCartFragment);
exports.useProductsColumnsInPreviewerUserFragment = useProductsColumnsInPreviewerUserFragment;
var useProductsColumnsInPreviewerLineItemFragment = (0, _client.gql)(_templateObject2(), _gqls.thumbnailFragment, _locale.localeFragment);
exports.useProductsColumnsInPreviewerLineItemFragment = useProductsColumnsInPreviewerLineItemFragment;