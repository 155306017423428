"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var getFontDefaultSetting = function getFontDefaultSetting(font) {
  return font === '黑體' ? 'Noto Sans TC, PingFang TC,微軟正黑體,Microsoft JhengHei,Helvetica Neue,Helvetica,source-han-sans-traditional,Arial,sans-serif' : "".concat(font, ", Noto Sans TC,\u5FAE\u8EDF\u6B63\u9ED1\u9AD4,Microsoft JhengHei,sans-serif");
};

var _default = getFontDefaultSetting;
exports["default"] = _default;