"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _color = _interopRequireDefault(require("color"));

var _default = function _default(id, normal, // minHeight
height, // active and hover
active, hover, colors, pattern, selected) {
  return "\n  #menu-".concat(id, ".show-border .searchBar,\n  #menu-").concat(id, ".show-border .is-login {\n    border-bottom: 1px solid ").concat((0, _color["default"])(normal.color || colors[selected[1]]).alpha(0.4), ";\n  }\n\n  #menu-").concat(id, " .ant-menu-vertical .menu-1,\n  #menu-").concat(id, " .ant-menu-vertical .menu-1 > .ant-menu-submenu-title {\n    min-height: ").concat(height, "px;\n  }\n\n  #menu-").concat(id, ".show-hover .menu-1.is-active {\n    ").concat(function () {
    var color = active.color || colors[selected[6]];
    var background = active.background || colors[selected[5]];
    var borderColor = active.borderColor || colors[selected[7]];

    switch (pattern) {
      case 0:
      default:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n            background: ").concat(background, ";\n          ");

      case 1:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n            background: ").concat(background, ";\n            border: 2px solid ").concat(borderColor, ";\n          ");

      case 2:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n          ");

      case 3:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n            background: ").concat(background, ";\n            box-shadow: inset 0px -5px ").concat(borderColor, ";\n          ");
    }
  }(), "\n  }\n\n  #menu-").concat(id, ".show-hover .menu-1:not(.searchBar).ant-menu-submenu-active > .ant-menu-submenu-title .meepshop-meep-ui__link-index__link,\n  #menu-").concat(id, ".show-hover .menu-1:not(.searchBar).ant-menu-item-active .meepshop-meep-ui__link-index__link,\n  #menu-").concat(id, ".show-hover .menu-1:not(.searchBar).ant-menu-submenu-active .meepshop-meep-ui__link-index__link {\n    ").concat(function () {
    var color = hover.color || colors[selected[3]];
    var borderColor = hover.borderColor || colors[selected[4]];

    switch (pattern) {
      case 2:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n            padding: 5px 0px;\n            border-bottom: 1px solid ").concat(borderColor, ";\n          ");

      default:
        return "";
    }
  }(), "\n  }\n\n  #menu-").concat(id, ".show-hover .menu-1:not(.searchBar):not(.ant-menu-submenu):hover,\n  #menu-").concat(id, ".show-hover .menu-1:not(.searchBar).ant-menu-submenu-active > .ant-menu-submenu-title {\n    ").concat(function () {
    var color = hover.color || colors[selected[3]];
    var background = hover.background || colors[selected[2]];
    var borderColor = hover.borderColor || colors[selected[4]];

    switch (pattern) {
      case 0:
      default:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n            background: ").concat(background, ";\n          ");

      case 1:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n            background: ").concat(background, ";\n            border: 2px solid ").concat(borderColor, ";\n          ");

      case 2:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n          ");

      case 3:
        return "\n            color: ".concat(color, ";\n            fill: ").concat(color, ";\n            background: ").concat(background, ";\n            ").concat(id === 'sidebar' ? "box-shadow: inset 5px 0px ".concat(borderColor) : "box-shadow: inset 0px -5px ".concat(borderColor), "\n            \n          ");
    }
  }(), "\n  }\n");
};

exports["default"] = _default;