"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productsInPreviewerLineItemFragment = exports.productsInPreviewerUserFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _useProductsColumns = require("./useProductsColumns");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment productsInPreviewerLineItemFragment on LineItem {\n    id\n    ...useProductsColumnsInPreviewerLineItemFragment\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment productsInPreviewerUserFragment on User {\n    id\n    ...useProductsColumnsInPreviewerUserFragment\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var productsInPreviewerUserFragment = (0, _client.gql)(_templateObject(), _useProductsColumns.useProductsColumnsInPreviewerUserFragment);
exports.productsInPreviewerUserFragment = productsInPreviewerUserFragment;
var productsInPreviewerLineItemFragment = (0, _client.gql)(_templateObject2(), _useProductsColumns.useProductsColumnsInPreviewerLineItemFragment);
exports.productsInPreviewerLineItemFragment = productsInPreviewerLineItemFragment;