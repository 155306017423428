"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.sort.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _queryString = _interopRequireDefault(require("query-string"));

var _isLogin = require("../../constants/isLogin");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = function _default(action, params, isLogin, isMobile) {
  switch (action) {
    case 1:
      {
        var path = params.path,
            pageId = params.pageId;
        if (pageId === 'Home') return '/';
        if (pageId && path) return "/pages/".concat(path);
        if (pageId) return "/page/".concat(pageId);
        return null;
      }

    case 2:
      return params.url;

    case 3:
      {
        var offset = params.from,
            limit = params.size,
            search = params.query_string,
            _params$sort = params.sort,
            field = _params$sort.field,
            order = _params$sort.order,
            _params$price = params.price,
            lte = _params$price.lte,
            gte = _params$price.gte,
            tags = params.tags,
            includedAllTags = params.includedAllTags;

        var query = _objectSpread(_objectSpread({
          includedAllTags: includedAllTags,
          offset: offset,
          limit: limit,
          sort: "".concat(field, "-").concat(order),
          price: "".concat(lte, ",").concat(gte)
        }, search ? {
          search: search
        } : {}), tags ? {
          tags: tags
        } : {});

        return "/products?".concat(_queryString["default"].stringify(query));
      }

    case 5:
      return isMobile ? null : '/cart';

    case 8:
      return isLogin === _isLogin.NOTLOGIN ? '/login' : null;

    default:
      return null;
  }
};

exports["default"] = _default;