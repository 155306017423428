"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BUILTIN_PLACEMENTS = exports.DEFAULT_COLOR_WITH_PATTERN = void 0;
var DEFAULT_COLOR_WITH_PATTERN = [[1, 2, 4, 2, null, 4, 2, null], [1, 2, 1, 2, 2, 1, 2, 2], [1, 2, null, 2, 2, null, 2, 2], [1, 2, 4, 2, 4, 1, 2, 1]];
exports.DEFAULT_COLOR_WITH_PATTERN = DEFAULT_COLOR_WITH_PATTERN;
var BUILTIN_PLACEMENTS = {
  bottomLeft: {
    points: ['tl', 'bl']
  },
  leftTop: {
    points: ['tl', 'bl']
  },
  rightTop: {
    points: ['tl', 'bl']
  }
};
exports.BUILTIN_PLACEMENTS = BUILTIN_PLACEMENTS;